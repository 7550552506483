<template>
  <b-card class="border-top-primary border-3 border-table-radius px-0">
    <b-card
      class="m-1 p-0"
      body-class="px-0"
    >
      <div class="mb-1 d-flex justify-content-between">
        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;">
          <v-select
            v-model="filters.range"
            v-b-tooltip.hover.top="'Range'"
            style="width: 220px !important;"
            :options="optionDates"
            :reduce="(option) => option.id"
            @input="onChangeDate"
          />
          <b-form-datepicker
            v-model="filters.from"
            v-b-tooltip.hover.top="'From'"
            class="ml-half-1"
            style="width: 220px !important;"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="en"
            @input="getProfitLossReport"
          />
          <b-form-datepicker
            v-model="filters.to"
            v-b-tooltip.hover.top="'To'"
            class="ml-1"
            style="width: 220px !important;"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="en"
            @input="getProfitLossReport"
          />
        </div>
        <b-dropdown
          no-caret
          text="Export"
          variant="light"
        >
          <b-dropdown-item @click="exportExcel">
            Export to Excel
          </b-dropdown-item>
          <b-dropdown-item @click="exportPdf">Export to PDF</b-dropdown-item>
        </b-dropdown>
      </div>
      <b-table
        id="idReportTable"
        ref="refReportTable"
        no-border-collapse
        class="position-relative"
        :fields="fields"
        show-empty
        no-provider-filtering
        responsive="sm"
        :items="reportData.filter(report => report.view)"
        :busy="isBusy"
        :tbody-tr-class="returnClass"
        @row-clicked="clickedRow"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template
          #cell(category)="data"
          class="p-0"
        >
          <span
            :style="'margin-left:'+ data.item.level * 10 + 'px'"
            :class="data.item.bold? 'k-font-weight-bold': ''"
          >
            <tabler-icon
              v-if="data.item.action && data.item.pather"
              icon="CaretRightIcon"
            />
            <tabler-icon
              v-else-if="data.item.action == false && data.item.pather == 1"
              icon="CaretDownIcon"
            />
            <span
              v-else
              style="margin-left: 7px"
            />
            {{ data.item.category }}
          </span>
        </template>
        <template #cell(total_d)="data">
          <span :class="data.item.bold || data.item.bold_t? 'k-font-weight-bold': ''">{{ data.item.total_d }}</span>
        </template>
        <template #cell(total_s)="data">
          <span :class="data.item.bold || data.item.bold_t? 'k-font-weight-bold': ''">{{ data.item.total_s }}</span>
        </template>
      </b-table>
    </b-card>
  </b-card>
</template>

<script>
import moment from 'moment'
import CompaniesService from '@/views/management/views/companies-old/companies.service'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      fields: [
        {
          key: 'category',
          label: '',
        },
        {
          key: 'total_d',
          label: '$ TOTAL',
        },
        {
          key: 'total_s',
          label: 'S/. TOTAL',
        },
      ],
      isBusy: false,
      optionDates: [{ id: 1, label: 'This Month' }, { id: 2, label: 'This Month-to-date' },
        { id: 3, label: 'This Quarter' }, { id: 4, label: 'This Quarter-to-date' }],
      filters: {
        from: '',
        to: '',
        range: 1,
        company_id: '',
      },
      reportData: [],
    }
  },
  async created() {
    this.isBusy = true
    this.filters.from = moment().startOf('month').format('YYYY-MM-DD')
    this.filters.to = moment().endOf('month').format('YYYY-MM-DD')
    this.filters.company_id = this.$route.params.id
    await this.getProfitLossReport()
    this.isBusy = false
  },
  methods: {
    async onChangeDate() {
      switch (this.filters.range) {
        case 1:
          this.filters.from = moment().startOf('month').format('YYYY-MM-DD')
          this.filters.to = moment().endOf('month').format('YYYY-MM-DD')
          break
        case 2:
          this.filters.from = moment().startOf('month').format('YYYY-MM-DD')
          this.filters.to = moment().format('YYYY-MM-DD')
          break
        case 3:
          this.filters.from = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD')
          this.filters.to = moment().endOf('month').format('YYYY-MM-DD')
          break
        case 4:
          this.filters.from = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD')
          this.filters.to = moment().format('YYYY-MM-DD')
      }
      this.isBusy = true
      await this.getProfitLossReport()
      this.isBusy = false
    },
    async getProfitLossReport() {
      try {
        this.isBusy = true
        const data = await CompaniesService.getProfitLossReport(this.filters)
        if (data.status === 200) {
          this.reportData = data.data
          this.isBusy = false
        }
      } catch (e) {
        this.isBusy = false
        this.showErrorSwal(e)
      }
    },
    clickedRow(item, index, event) {
      this.showHide(item, index)
    },
    returnClass(item) {
      const whenFather = this.isDarkSkin ? 'table-light2 text-secondary cursor-pointer' : 'table-light text-secondary cursor-pointer'
      const whenFooter = 'table-dark k-font-weight-bold text-uppercase bg-so-dark border-light'
      if (item !== null) {
        if (item.pather) {
          return whenFather
        } if (item.footer_t) {
          return whenFooter
        }
        return 'text-secondary'
      }
    },
    showHide(item, index) {
      if (item.pather) {
        let fin = false
        this.reportData.forEach((element, i) => {
          if (i > index && fin === false) {
            if (element.level > item.level) {
              element.view = item.action
            }
            if (element.level === item.level) {
              fin = true
            }
          }
        })
        item.action = !item.action
      }
    },
    async exportExcel() {
      try {
        this.addPreloader()
        const data = await CompaniesService.exportExcelReportProfitLoss({
          from: this.filters.from,
          to: this.filters.to,
          company_id: this.filters.company_id,
        })
        if (data.status === 200) {
          this.removePreloader()
          await this.forceFileDownload(data.data, `REPORT_PROFIT_&_LOSS_${moment(this.filters.from).format('MM-DD-YYYY')}_${moment(this.filters.to).format('MM-DD-YYYY')}.xlsx`)
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },
    async exportPdf() {
      try {
        this.addPreloader()
        const data = await CompaniesService.exporPdfReportProfitLoss({
          from: this.filters.from,
          to: this.filters.to,
          company_id: this.$route.params.id,
        })
        if (data.status === 200) {
          this.removePreloader()
          window.open(data.data)
        }
      } catch (e) {
        this.removePreloader()
        this.showToast('danger', 'top-right', 'Error', 'XIcon', 'There was an error generating the pdf, please try again')
      }
    },
  },
}
</script>

<style>
.bg-so-dark{
  color: #363639FF !important;
}
.table-light2{
  background-color: #222222 !important;
}
</style>
